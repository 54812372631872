




















































































































































































































































































































































































































@use "@material/checkbox"; // Required only for data table with row selection.
@use "@material/icon-button"; // Required only for data table with column sorting.
@use "@material/data-table/data-table";

@include checkbox.core-styles;
@include icon-button.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;

html {
  overflow-y: scroll;
}

#portfolio {
  margin: auto;
  width: 98%;
  margin-top: 20px;
}

#table-header {
  background-color: white;
}

.primary-container{
  height: 100%;
}

.options {
  display: flex;
  width: 400px;

}

.create-portfolio {
  margin-right: 10px;
  font-size: 13px !important;
  margin-left: 0px !important;
  background-color: #5bb75b;
}

.preferences {
  margin-left: 10px;
  font-size: 13px !important;
}

.input-form {
  width: 200px;
  text-align: center;
}

a {
  text-decoration: none;
}

.material-icons.portfolio {
  color: #00add8;
  font-size: 19px;
  cursor: pointer;
  margin-right: 4px;
}

.material-symbols-rounded {
  color: #00add8;
  font-size: 19px;
  cursor: pointer;
  margin-right: 4px;
  font-variation-settings:
    'FILL' 1,
}

.material-symbols-rounded.no-activate {
  font-variation-settings:
    'FILL' 0,
}

@media (max-height: 500px) {
  #portfolio {
    margin-top: 60px;
    margin-left: 12px;
  }
}

#main-container {
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}

.iframe-container {
  height: 100% !important;
}

header {
  display: none !important;
}

.container-image {
  /* Aplica un margen automático para centrar el div horizontalmente */
  margin: 0 auto;
  /* Ajusta el ancho máximo del contenedor */
  max-width: 800px;
  max-height: auto;

}



.container-image img {
  max-width: 500px;
  max-height: 800px;
  margin: 20px;
  border-radius: 5px;
}

@media screen and (min-width: 768px) {
  .container-image img {
    width: 500px;
  }
}

.text-center {
  text-align: center;
  margin: 20px;
}

.container-image p {
  text-align: justify;
}

.unauthorized {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    justify-content: center;
    font-size: 20px;
}

.login-button{
    color: white;
    background-color: #00add8;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }
  .login-button:hover{
    background-color: #00bdec;
  }

  .not-allowed-portfolio-container{
    text-align: center;
    margin-top: 50px;
  }
  
  .link-to-store{
    color: #35add8 !important;
  }

  .not-allowed-portfolio-title{
    color: #FF9B00;
    font-weight: bold;
  }

  .anonymous-portoflio {
    opacity: 100% !important;
  }

  /* Initially hide the main content but keep it rendered */
  .mdc-layout-grid__cell--span-12 {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  /* When loading finishes, fade it in */
  .loading-visible {
    opacity: 1;
  }

  /* Keep it hidden during loading */
  .loading-hidden {
    opacity: 0;
  }

  /* Make the loading indicator cover the full screen */
  .full-screen-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: var(--color-background, #fff);
    display: flex;
    justify-content: center;
    align-items: center;
  }

